module.exports = [{
      plugin: require('../../../features/poweroutagemap/gatsby-browser.js'),
      options: {"plugins":[],"url":"/"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../.yarn/unplugged/gatsby-virtual-19d20139ac/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
